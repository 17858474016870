//export const BASE_URL = "http://localhost:8081"; 
//export const BASE_URL = "http://192.168.248.18:8081"; // live server
//export const BASE_URL = "http://192.168.170.18:8081"; // live server
export const BASE_URL = "https://schoolapi.examamine.com"; // live server
//export https://api.examamine.com/
//export const IMAGE_URL = "https://examamine.com/file/"; 
//export const IMAGE_URL = "https://examamine.com/file/"; 
//localserver
//export const BASE_URL = "http://192.168.0.104:3000"; // live server


